import { MouseEvent, ReactElement, useCallback, useMemo } from "react";
import styled from "styled-components";

import { modalsModel, useModalsService } from "../../../machines/modals.machine";
import mediaQuery from "../../../theme/mediaQuery";
import { Salary } from "../../../types";

const OuterWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
`;

const ActionWrapper = styled.a`
  ${props => props.theme.snippets.borderAliceBlue}
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 1.25rem;
  cursor: pointer;
  font-size: 0.875rem;
  justify-content: center;
  margin-right: 0;
  padding: 0.3125rem 1.25rem;
  transition: background-color 200ms ease;

  &:active,
  &:hover {
    background-color: ${props => props.theme.colors.ghostWhite};
  }

  ${props => mediaQuery.lessThan("medium")`
    background-color: ${props.theme.colors.darkGunmetalBlack} !important;
    border: 1px solid ${props.theme.colors.darkGunmetalBlack} !important;
    border-radius: 1.5rem;
    height: 3rem;
    width: 3rem;

    & > * {
      color: ${props.theme.colors.white} !important;
    }
  `}
`;

const MigrateSpan = styled.span`
  color: ${props => props.theme.colors.darkGunmetalBlack};
`;

interface CellActionsProps {
  salary: Salary;
}

function CellActions({ salary }: CellActionsProps): ReactElement {
  const modalsService = useModalsService();

  const salaryUrl = useMemo((): string => {
    if (salary.id) {
      return `https://pay.sablier.finance/stream/${salary.id}`;
    } else {
      return "https://pay.sablier.finance";
    }
  }, [salary]);

  const onClickMigrate = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      modalsService.send(modalsModel.events.openMigrate(salary));
    },
    [modalsService, salary],
  );

  return (
    <OuterWrapper>
      <ActionWrapper href={salaryUrl} rel="noopener noreferrer" target="_blank">
        <MigrateSpan>Open</MigrateSpan>
      </ActionWrapper>

      <ActionWrapper onClick={onClickMigrate} style={{ marginLeft: "0.5rem" }}>
        <MigrateSpan>Migrate</MigrateSpan>
      </ActionWrapper>
    </OuterWrapper>
  );
}

export default CellActions;
