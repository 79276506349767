import { gql } from "@apollo/client";

import { CUTOFF_TIMESTAMP } from "../constants/time";
import getNow from "../helpers/getNow";

const CORE_SALARY = gql`
  fragment Stream on Stream {
    id
    cancellation {
      id
      timestamp
    }
    deposit
    ratePerSecond
    recipient
    sender
    startTime
    stopTime
    timestamp
    token {
      address: id
      decimals
      name
      symbol
    }
    withdrawals {
      id
      amount
    }
  }
`;

export const GET_CANCELLATION_SENDER_BALANCE = gql`
  query CancellationSenderBalance($salaryId: ID!) {
    cancellation(id: $salaryId) {
      id
      senderBalance
    }
  }
`;

export const GET_SENDER_SALARIES = gql`
  ${CORE_SALARY}
  query Streams($sender: String!) {
    salaries: streams(
      first: 500,
      orderBy: timestamp,
      orderDirection: desc,
      where: {
        sender: $sender,
        stopTime_gt: ${getNow()},
        timestamp_lt: ${CUTOFF_TIMESTAMP}
      }
    )
    {
      ...Stream
    }
  }
`;
