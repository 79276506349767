/* eslint-disable no-param-reassign */
/// Formats a number to a human-readable currency amount.
/// @param number the number to round
/// @param decimals the rounding precision
/// @param roundSmallNumbersToZero whether to return "~0" for very small numbers (< 0.001)
/// @see https://bit.ly/2NwjlHQ
export default function formatCash(no: number, decimals: number = 2, roundSmallNumbersToZero: boolean = true): string {
  // Terminate early if the number doesn't exist, it's 0 or lower than 0.001.
  if (no === 0) {
    return "0";
  }
  if (!no) {
    return "";
  }
  if (no < 1e-3) {
    if (roundSmallNumbersToZero) {
      return `<${(1e-3).toLocaleString()}`;
    } else {
      return no.toLocaleString();
    }
  }
  if (no < 1e4) {
    return no.toLocaleString();
  }

  // Number of decimal places to show.
  decimals = !decimals || decimals < 0 ? 0 : decimals;

  // Get power.
  const b: string[] = no.toPrecision(2).split("e");

  // Floor at decimals, ceiling at trillions.
  const k: number = b.length === 1 ? 0 : Math.floor(Math.min(Number(b[1].slice(1)), 14) / 3);

  // Floor at decimals, ceiling at trillions.
  const c: number = Number(k < 1 ? no.toFixed(0 + decimals) : (no / 10 ** (k * 3)).toFixed(1 + decimals));

  // Enforce -0 is 0.
  const d = c < 0 ? c : Math.abs(c);

  // Append power.
  const e = d + ["", "K", "M", "B", "T"][k];

  return e;
}
