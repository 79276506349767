import { useQuery } from "@apollo/client";

import { GET_CANCELLATION_SENDER_BALANCE } from "../apollo/queries";
import { Cancellation } from "../types";

interface CancellationSenderBalanceQueryData {
  cancellation?: Cancellation;
}

interface CancellationSenderBalanceQueryVars {
  salaryId: string;
}

export default function useCancellationSenderBalance(salaryId?: string) {
  const { data, error, loading, startPolling, stopPolling } = useQuery<
    CancellationSenderBalanceQueryData,
    CancellationSenderBalanceQueryVars
  >(GET_CANCELLATION_SENDER_BALANCE, { variables: { salaryId: salaryId || "" } });

  return {
    cancellationSenderBalance: data?.cancellation?.senderBalance,
    error,
    loading,
    startPolling,
    stopPolling,
  };
}
