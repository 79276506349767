import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import { Wallet } from "bnc-onboard/dist/src/interfaces";
import { ReactElement, ReactNode, useEffect } from "react";

import createOnboard from "../helpers/createOnboard";
import getNetworkName from "../helpers/getNetworkName";
import useWeb3Store from "../hooks/useWeb3Store";

interface OnboardManagerProps {
  children: ReactNode;
}

function OnboardManager({ children }: OnboardManagerProps): ReactElement {
  const [store, dispatch] = useWeb3Store();

  // Initialize onboard.js
  useEffect(() => {
    if (store.onboard) {
      return;
    }

    const newOnboard = createOnboard({
      address: function (newAddress: string) {
        if (newAddress) {
          dispatch({ type: "SET_WALLET_ADDRESS", payload: newAddress });
        } else {
          dispatch({ type: "SET_WALLET_ADDRESS", payload: "" });
        }
      },
      network: function (newNetworkId: number) {
        if (newNetworkId) {
          dispatch({ type: "SET_NETWORK_ID", payload: newNetworkId });
          dispatch({ type: "SET_NETWORK_NAME", payload: getNetworkName(newNetworkId) });
        } else {
          dispatch({ type: "SET_NETWORK_ID", payload: 0 });
          dispatch({ type: "SET_NETWORK_NAME", payload: "" });
        }
      },
      wallet: function (newWallet: Wallet) {
        if (newWallet.provider) {
          const newProvider: Web3Provider = new Web3Provider(newWallet.provider);
          dispatch({ type: "SET_WALLET_PROVIDER", payload: newProvider });
        } else {
          dispatch({ type: "SET_WALLET_PROVIDER", payload: null });
        }
      },
    });

    dispatch({ type: "SET_ONBOARD", payload: newOnboard });
  }, [dispatch, store.onboard]);

  // Connect an unchecked instance of JsonRpcSigner.
  // See https://github.com/blocknative/react-demo/issues/82.
  useEffect(() => {
    if (store.wallet.provider && store.wallet.address) {
      const newSigner: JsonRpcSigner = store.wallet.provider.getSigner(store.wallet.address).connectUnchecked();
      dispatch({ type: "SET_WALLET_SIGNER", payload: newSigner });
    } else {
      dispatch({ type: "SET_WALLET_SIGNER", payload: null });
    }
  }, [dispatch, store.onboard, store.wallet.address, store.wallet.provider]);

  return <>{children}</>;
}

export default OnboardManager;
