import { BigNumber } from "@ethersproject/bignumber";
import { MaxUint256 } from "@ethersproject/constants";
import { Contract } from "@ethersproject/contracts";
import { TransactionResponse } from "@ethersproject/providers";
import { createModel } from "xstate/lib/model";
import { ErrorPlatformEvent } from "xstate/lib/types";

import { CREATE_STREAM_GAS_LIMIT } from "../constants/gas";
import { STREAM_START_DELAY } from "../constants/time";
import getNow from "../helpers/getNow";
import { Salary } from "../types";

/// TYPES ///
interface MigrationModelContext {
  error: string | null;
}

interface EventWithData {
  data: any;
}

/// MODEL ///

export const migrationModel = createModel(
  {
    error: null,
    migratedSalaryId: "",
  } as MigrationModelContext,
  {
    events: {
      cancel: (data: any) => ({ data }),
      recreate: (data: any) => ({ data }),
      reset: () => ({}),
    },
  },
);

/// INVOCATIONS ///

async function submitCancellation(data: any): Promise<void> {
  const migratedSalaryId: string = data.migratedSalaryId;
  const payrollContract: Contract = data.payrollContract;
  if (!migratedSalaryId || !payrollContract) {
    throw new Error("Oops! An unknown error occurred. Please refresh the page.");
  }

  const tx: TransactionResponse = await payrollContract.cancelSalary(data.migratedSalaryId);
  await tx.wait(1);
}

async function submitRecreation(data: any): Promise<void> {
  const cancellationSenderBalance: string = data.cancellationSenderBalance;
  const migratedSalary: Salary = data.migratedSalary;
  const sablierContract: Contract | null = data.sablierContract;
  const tokenContract: Contract | null = data.tokenContract;
  const walletAddress: string = data.walletAddress;
  if (!cancellationSenderBalance || !migratedSalary || !sablierContract || !tokenContract || !walletAddress) {
    throw new Error("Oops! An unknown error occurred. Please refresh the page.");
  }

  const txs: TransactionResponse[] = [];

  // The token approval tx.
  const allowance: BigNumber = await tokenContract.allowance(walletAddress, sablierContract.address);
  if (allowance.lt(cancellationSenderBalance)) {
    const approvalTx: TransactionResponse = await tokenContract.approve(sablierContract.address, MaxUint256);
    txs.push(approvalTx);
  }

  // The stream creation tx.
  const now: BigNumber = BigNumber.from(getNow());
  const startTime: BigNumber = now.add(STREAM_START_DELAY);
  const stopTime: BigNumber = BigNumber.from(migratedSalary.stopTime).add(STREAM_START_DELAY);
  const totalSeconds: BigNumber = stopTime.sub(startTime);

  const remainder: BigNumber = BigNumber.from(cancellationSenderBalance).mod(totalSeconds);
  const deposit: BigNumber = BigNumber.from(cancellationSenderBalance).sub(remainder);

  if (totalSeconds.gt(deposit)) {
    throw new Error(
      "Streams can't be created when the duration amount, measured in seconds, is greater than the token amount",
    );
  }

  const createStreamTx: TransactionResponse = await sablierContract.createStream(
    migratedSalary.recipient,
    deposit,
    tokenContract.address,
    startTime,
    stopTime,
    {
      gasLimit: CREATE_STREAM_GAS_LIMIT,
    },
  );
  txs.push(createStreamTx);

  await Promise.all(txs.map((tx: TransactionResponse) => tx.wait(1)));
}

/// ACTIONS ///

const clearError = migrationModel.assign({
  error: null,
});

const setError = migrationModel.assign({
  error: (_, event) => (event as ErrorPlatformEvent).data.message,
});

/// MACHINE ///

export function createMigrationMachine() {
  return migrationModel.createMachine({
    id: "migrationMachine",
    context: migrationModel.initialContext,
    initial: "idle",
    states: {
      idle: {
        on: {
          cancel: "cancelling",
        },
      },
      cancelling: {
        invoke: {
          src: (_context, event) => submitCancellation((event as EventWithData).data),
          onDone: "cancelled",
          onError: "cancelFailure",
        },
      },
      cancelled: {
        on: {
          recreate: "recreating",
          reset: "idle",
        },
      },
      cancelFailure: {
        entry: setError,
        exit: clearError,
        on: {
          cancel: "cancelling",
          reset: "idle",
        },
      },
      recreating: {
        invoke: {
          src: (_context, event) => submitRecreation((event as EventWithData).data),
          onDone: "success",
          onError: "recreateFailure",
        },
      },
      recreateFailure: {
        entry: setError,
        exit: clearError,
        on: {
          recreate: "recreating",
          reset: "idle",
        },
      },
      success: {
        on: {
          reset: "idle",
        },
      },
    },
  });
}
