import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { Zero } from "@ethersproject/constants";

export default function getSalaryShare(
  value: BigNumberish,
  salaryDuration: BigNumberish,
  ownedDuration: BigNumberish,
): BigNumber {
  if (BigNumber.from(ownedDuration).lte(Zero)) {
    return Zero;
  }
  if (BigNumber.from(ownedDuration).gte(salaryDuration)) {
    return BigNumber.from(value);
  }
  return BigNumber.from(value).mul(ownedDuration).div(salaryDuration);
}
