import { ReactElement } from "react";
import styled, { css } from "styled-components";

import useShowSpinner from "../hooks/useShowSpinner";
import colors from "../theme/colors";

interface WrapperProps {
  borderColor: string;
  color: string;
  size: number;
}

const Wrapper = styled.div<WrapperProps>`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  ${props => props.theme.snippets.flexRowNoWrap}
  animation: spin 1000ms cubic-bezier(0.785, 0.135, 0.15, 0.85) infinite;
  border-radius: 50%;

  ${props => css`
    border: ${props.size / 24}rem solid ${props.borderColor};
    border-top: ${props.size / 12}rem solid ${props.color};
    height: ${props.size}rem;
    width: ${props.size}rem;
  `}
`;

interface SpinnerProps {
  borderColor?: string;
  color?: string;
  delay?: number;
  size?: number;
}

function Spinner({
  borderColor = colors.aliceBlue,
  color = colors.primary,
  delay = 750,
  size = 3,
}: SpinnerProps): ReactElement | null {
  const [showSpinner] = useShowSpinner(delay);
  if (showSpinner) {
    return <Wrapper borderColor={borderColor} color={color} size={size} />;
  } else {
    return null;
  }
}

export default Spinner;
