import { ReactElement, useCallback } from "react";
import { User as UserIcon } from "react-feather";
import styled from "styled-components";

import useWeb3Store from "../hooks/useWeb3Store";
import colors from "../theme/colors";
import mediaQuery from "../theme/mediaQuery";
import AccountLabel from "./AccountLabel";
import Identicon from "./Identicon";

const borderRadiusSize: string = "1.25rem";

const OuterWrapper = styled.div`
  ${props => props.theme.snippets.borderAliceBlue}
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  border-radius: ${borderRadiusSize};
  font-size: 0.875rem;

  ${mediaQuery.lessThan("medium")`
    border: none;
  `};
`;

const InnerWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${borderRadiusSize};
  cursor: pointer;
  font-weight: 400;
  height: 2.5rem;
  justify-content: center;
  min-width: 8.5rem;
  padding: 0.5rem 1rem;
  transition: background-color 200ms ease;
  user-select: none;

  &:active,
  &:hover {
    background-color: ${props => props.theme.colors.ghostWhite};
  }

  ${props => mediaQuery.lessThan("medium")`
    background-color: ${props.theme.colors.ghostWhite};
    border: 1px solid ${props.theme.colors.aliceBlue};
    margin: 0rem 0.375rem;
    min-width: 0;
    padding: 0rem 0.75rem;
    width: auto;

    &:active,
    &:hover {
      background-color: ${props.theme.colors.white};
    }
  `};
`;

const AccountLabelWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  margin: 0rem 0.3125rem 0rem 0.25rem;
`;

const StatusLabel = styled.span`
  color: ${props => props.theme.colors.darkGunmetalBlack};
  margin-left: 0.35rem;
  margin-right: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledUserIcon = styled(UserIcon)`
  height: 0.875rem;
  width: 0.875rem;
`;

function Web3Tab(): ReactElement {
  const [store] = useWeb3Store();

  const onClickWeb3Button = useCallback(async () => {
    if (store.onboard) {
      const walletSelected: boolean = await store.onboard.walletSelect();
      if (walletSelected) {
        await store.onboard.walletCheck();
      }
    }
  }, [store.onboard]);

  const renderStatus = useCallback(() => {
    if (store.wallet.provider && store.wallet.address) {
      return (
        <>
          <Identicon />
          <AccountLabelWrapper>
            <AccountLabel account={store.wallet.address} />
          </AccountLabelWrapper>
        </>
      );
    } else {
      return (
        <>
          <StyledUserIcon color={colors.darkGunmetalBlack} />
          <StatusLabel>Connect Wallet</StatusLabel>
        </>
      );
    }
  }, [store.wallet.address, store.wallet.provider]);

  return (
    <OuterWrapper onClick={onClickWeb3Button}>
      <InnerWrapper>{renderStatus()}</InnerWrapper>
    </OuterWrapper>
  );
}

export default Web3Tab;
