import { ReactElement } from "react";
import styled from "styled-components";

import { ReactComponent as Logo } from "../assets/logo.svg";
import mediaQuery from "../theme/mediaQuery";
import Web3Tab from "./Web3Tab";

const OuterWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  background-color: ${props => props.theme.colors.white};
  border-color: ${props => props.theme.colors.aliceBlue};
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  height: 4.5rem;
  justify-content: center;
  left: 0rem;
  top: 0rem;
  width: 100%;
  z-index: 100;
`;

const InnerWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  flex-grow: 1;
  height: 4.5rem;
  justify-content: flex-start;
  max-width: 1264px;
  padding: 0rem 2rem;

  ${mediaQuery.lessThan("medium")`
    padding: 0rem 0.5rem;
  `}
`;

const LeftSideWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  margin: auto;
  margin-left: 0rem;
`;

const StyledLogo = styled(Logo)`
  align-items: center;
  display: flex;
  height: 4rem;
  object-fit: contain;
  width: 10rem;

  ${mediaQuery.lessThan("medium")`
    height: 3.25rem;
    padding: 0rem 0.5rem;
    width: 8rem;
  `}
`;

const RightSideWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  margin: auto;
  margin-right: 0rem;
`;

function Header(): ReactElement {
  return (
    <OuterWrapper>
      <InnerWrapper>
        <LeftSideWrapper>
          <StyledLogo />
        </LeftSideWrapper>
        <RightSideWrapper>
          <Web3Tab />
        </RightSideWrapper>
      </InnerWrapper>
    </OuterWrapper>
  );
}

export default Header;
