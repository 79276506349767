import { ApolloProvider } from "@apollo/client";
import { ReactNode, StrictMode } from "react";
import ReactDOM from "react-dom";

import apolloClient from "./apollo/client";
import App from "./App";
import OnboardManager from "./components/OnboardManager";
import { ModalsContextProvider } from "./contexts/Modals";
import { Web3StoreContextProvider } from "./contexts/Web3Store";
import ThemeProvider, { GlobalStyle } from "./theme";

function LocalContextProviders({ children }: { children: ReactNode }) {
  return (
    <Web3StoreContextProvider>
      <ModalsContextProvider>{children}</ModalsContextProvider>
    </Web3StoreContextProvider>
  );
}

ReactDOM.render(
  <StrictMode>
    <ApolloProvider client={apolloClient}>
      <LocalContextProviders>
        <ThemeProvider>
          <GlobalStyle />
          <OnboardManager>
            <App />
          </OnboardManager>
        </ThemeProvider>
      </LocalContextProviders>
    </ApolloProvider>
  </StrictMode>,
  document.getElementById("root"),
);
