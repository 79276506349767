import Onboard from "bnc-onboard";
import { API, Subscriptions } from "bnc-onboard/dist/src/interfaces";

import { RINKEBY_ID } from "../constants/networks";
import WALLETS from "../constants/wallets";
import getEnvVar from "./getEnvVar";

export default function createOnboard(subscriptions: Subscriptions): API {
  return Onboard({
    dappId: getEnvVar("REACT_APP_BLOCKNATIVE_API_KEY"),
    networkId: Number(getEnvVar("REACT_APP_NETWORK_ID")),
    networkName: getEnvVar("REACT_APP_NETWORK_NAME"),
    subscriptions,
    walletCheck: [
      { checkName: "accounts" },
      { checkName: "connect" },
      { checkName: "derivationPath" },
      { checkName: "network" },
    ],
    walletSelect: {
      description: "Please select a wallet to connect to Sablier: Migrator v1.1",
      wallets: WALLETS,
    },
  });
}
