import { GET_SENDER_SALARIES } from "../apollo/queries";
import useActiveSalaries from "./useActiveSalaries";
import useWeb3Store from "./useWeb3Store";

export default function useSenderActiveSalaries() {
  const [store] = useWeb3Store();
  const { error, loading, refetch, salaries } = useActiveSalaries(GET_SENDER_SALARIES, {
    variables: { sender: store.wallet.address.toLowerCase() || "" },
  });
  return { error, loading, refetch, salaries };
}
