import { ReactElement } from "react";
import styled, { css } from "styled-components";

import colors from "../theme/colors";

interface WrapperProps {
  backgroundColor: string;
  isGrayscale: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${props => props.theme.snippets.borderAliceBlue}
  background-color: ${props => props.backgroundColor};
  border-radius: 0.1875rem;
  flex-grow: 1;
  height: 0.4rem;
  margin: 0rem;

  ${props =>
    props.isGrayscale &&
    css`
      filter: isGrayscale(100%);
    `};
`;

interface FillerProps {
  width: number;
}

const Filler = styled.div<FillerProps>`
  ${props => props.theme.gradients.secondary}
  border-radius: inherit;
  height: 100%;
  max-width: 100%;
  transition: width 200ms ease-in;
  width: ${props => props.width}%;
`;

interface ProgressBarProps {
  backgroundColor?: string;
  color?: string;
  isGrayscale?: boolean;
  progress?: number;
}

function ProgressBar({
  backgroundColor = colors.white,
  color = "",
  isGrayscale = false,
  progress = 0,
}: ProgressBarProps): ReactElement {
  return (
    <Wrapper backgroundColor={backgroundColor} isGrayscale={isGrayscale}>
      <Filler color={color} width={progress} />
    </Wrapper>
  );
}

export default ProgressBar;
