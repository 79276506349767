import { Contract, ContractInterface } from "@ethersproject/contracts";
import { useMemo } from "react";

import useWeb3Store from "./useWeb3Store";

export default function useContract(
  abi: ContractInterface,
  address?: string,
  withSignerIfPossible: boolean = true,
): Contract | null {
  const [store] = useWeb3Store();

  return useMemo(() => {
    if (!address || !store.network.id) {
      return null;
    }

    if (withSignerIfPossible && store.wallet.signer) {
      return new Contract(address, abi, store.wallet.signer);
    } else if (store.wallet.provider) {
      return new Contract(address, abi, store.wallet.provider);
    }

    return null;
  }, [abi, address, store.network.id, store.wallet.provider, store.wallet.signer, withSignerIfPossible]);
}
