import { BigNumberish } from "@ethersproject/bignumber";

import getRecipientSalaryShare from "./getRecipientSalaryShare";

export default function getRecipientSalaryShareAsPercentage(
  startTime: BigNumberish,
  stopTime: BigNumberish,
  cancellationTime?: BigNumberish,
): number {
  return getRecipientSalaryShare(10000, startTime, stopTime, cancellationTime).toNumber() / 100;
}
