import { ReactElement, ReactNode } from "react";
import styled, { css } from "styled-components";

import mediaQuery from "../../../theme/mediaQuery";
import { TableColumnId } from "../../../types";

interface WrapperProps {
  columnId: TableColumnId;
  flexWeight: number;
  reorder: number;
}

const Wrapper = styled.div<WrapperProps>`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  flex-basis: 0%;
  flex-grow: ${props => props.flexWeight};
  flex-shrink: ${props => props.flexWeight};
  flex-wrap: wrap;
  justify-content: center;
  padding: 0rem 0.4rem;
  text-align: center;

  ${props => mediaQuery.lessThan("medium")`
    border-bottom: 1px solid ${props.theme.colors.aliceBlue};
    justify-content: flex-start;
    min-height: 1rem;
    flex: auto;
    order: ${props.reorder};
    padding: 0.8rem 0.6rem;
    width: 100%;

    ${
      props.reorder < 0 &&
      css`
        display: none;
      `
    }

    ${
      props.columnId === "status" &&
      css`
        border-bottom: none;
        padding: 0;
        position: absolute;
        right: 0.8125rem;
        top: 1rem;
        width: auto;
      `
    }

    ${
      (props.columnId === "to" || props.columnId === "stopTime") &&
      css`
        background-color: ${props.theme.colors.background};
      `
    }

    ${
      props.columnId === "value" &&
      css`
        min-height: 4rem;
        padding-left: 0.8rem;

        & > * {
          max-width: calc(100% - 7rem);
        }
      `
    }

    ${
      props.columnId === "progress" &&
      css`
        padding: 1.2rem 0.5rem;
      `
    }

    ${
      props.columnId === "actions" &&
      css`
        display: none;
      `
    }
  `}
`;

interface CellProps {
  children: ReactNode;
  columnId: TableColumnId;
  flexWeight: number;
  reorder: number;
}

function Cell({ children, columnId, flexWeight, reorder }: CellProps): ReactElement {
  return (
    <Wrapper columnId={columnId} reorder={reorder} flexWeight={flexWeight}>
      {children}
    </Wrapper>
  );
}

export default Cell;
