import { formatUnits } from "@ethersproject/units";
import dayjs from "dayjs";
import { ReactElement, useCallback, useMemo } from "react";
import styled from "styled-components";

import { ISO_8601_FORMAT } from "../../constants/time";
import formatCash from "../../helpers/formatCash";
import getRecipientSalaryShareAsPercentage from "../../helpers/getRecipientSalaryShareAsPercentage";
import getSalaryStatus from "../../helpers/getSalaryStatus";
import mediaQuery from "../../theme/mediaQuery";
import { Salary, SalaryStatus, TableColumn, TableColumnId } from "../../types";
import AccountLabel from "../AccountLabel";
import Cell from "./cell/Cell";
import CellActions from "./cell/CellActions";
import CellAmount from "./cell/CellAmount";
import CellProgress from "./cell/CellProgress";
import CellStatus from "./cell/CellStatus";
import CellText from "./cell/CellText";
import Header from "./TableHeader";
import Row from "./TableRow";

const OuterWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
`;

const InnerWrapper = styled.div`
  width: 100%;

  ${mediaQuery.lessThan("medium")`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    padding: 0rem 1rem;
  `}

  ${mediaQuery.lessThan("small")`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    padding: 0rem 0.5rem;
  `}
`;

interface TableProps {
  salaries: Salary[];
}

function Table({ salaries }: TableProps): ReactElement {
  const columns = useMemo((): TableColumn[] => {
    return [
      {
        flexWeight: 1,
        id: "status",
        label: "Status",
        reorder: 1,
      },
      {
        flexWeight: 1,
        id: "to",
        label: "To",
        reorder: 2,
      },
      {
        flexWeight: 1,
        id: "value",
        label: "Value",
        reorder: 1,
      },
      {
        flexWeight: 1.5,
        id: "progress",
        label: "Streamed",
        reorder: 5,
      },
      {
        flexWeight: 1.5,
        id: "startTime",
        label: "Start Time",
        reorder: 3,
      },
      {
        flexWeight: 1.5,
        id: "stopTime",
        label: "Stop Time",
        reorder: 4,
      },
      {
        flexWeight: 1.75,
        id: "actions",
        label: "Actions",
        reorder: 4,
      },
    ];
  }, []);

  const renderCell = useCallback((columnId: TableColumnId, salary: Salary): ReactElement | null => {
    const status: SalaryStatus = getSalaryStatus(salary);

    switch (columnId) {
      case "status": {
        return <CellStatus status={status} withdrawable />;
      }
      case "to": {
        return (
          <CellText>
            <AccountLabel account={salary.recipient} />
          </CellText>
        );
      }
      case "value": {
        return (
          <CellAmount style={{ marginRight: "0.75rem" }}>
            {formatCash(Number(formatUnits(salary.deposit, salary.token.decimals)))}&nbsp;{salary.token.symbol}
          </CellAmount>
        );
      }
      case "progress": {
        return (
          <CellProgress
            isCancelled={status === SalaryStatus.Cancelled}
            value={getRecipientSalaryShareAsPercentage(
              salary.startTime,
              salary.stopTime,
              salary.cancellation?.timestamp,
            )}
          />
        );
      }
      case "startTime": {
        return <CellText>{dayjs.unix(salary.startTime).format(ISO_8601_FORMAT)}</CellText>;
      }
      case "stopTime": {
        return <CellText>{dayjs.unix(salary.stopTime).format(ISO_8601_FORMAT)}</CellText>;
      }
      case "actions": {
        return <CellActions salary={salary} />;
      }
      default:
        return null;
    }
  }, []);

  return (
    <OuterWrapper>
      <InnerWrapper>
        <Header columns={columns} />
        {salaries.map((salary: Salary) => {
          return (
            <Row key={salary.id}>
              {columns.map(({ flexWeight, id, reorder }) => {
                return (
                  <Cell columnId={id} flexWeight={flexWeight} key={`${salary.id}-${id}`} reorder={reorder}>
                    {renderCell(id, salary)}
                  </Cell>
                );
              })}
            </Row>
          );
        })}
      </InnerWrapper>
    </OuterWrapper>
  );
}

export default Table;
