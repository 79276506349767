import { ApolloClient, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import getEnvVar from "../helpers/getEnvVar";

const subgraphUrl: string = getEnvVar("REACT_APP_SUBGRAPH_URL");

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache: new InMemoryCache(),
  uri: subgraphUrl,
});

export default client;
