import { DocumentNode, QueryHookOptions, useQuery } from "@apollo/client";

import getSalaryStatus from "../helpers/getSalaryStatus";
import { Salary, SalaryStatus } from "../types";

interface ActiveSalariesQueryData {
  salaries: Salary[];
}

interface ActiveSalariesQueryVars {
  recipient?: string;
  sender?: string;
}

export default function useActiveSalaries(
  query: DocumentNode,
  options: QueryHookOptions<ActiveSalariesQueryData, ActiveSalariesQueryVars>,
) {
  const { data, error, loading, refetch } = useQuery<ActiveSalariesQueryData, ActiveSalariesQueryVars>(query, options);

  let salaries: Salary[] = [];

  if (data?.salaries) {
    // Exclude the inactive streams.
    salaries = data.salaries.filter((salary: Salary) => {
      return [SalaryStatus.Created, SalaryStatus.Streaming].includes(getSalaryStatus(salary));
    });

    // Exclude the streams with ids higher than 100,000.
    salaries = salaries.filter((salary: Salary) => {
      return Number(salary.id) < 100000;
    });
  }

  return {
    error,
    loading,
    refetch,
    salaries,
  };
}
