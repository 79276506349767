import { createModel } from "xstate/lib/model";

import { createXStateContext } from "../helpers/createXStateContext";
import { Salary } from "../types";

interface ModalsModelContext {
  migratedSalary: Salary | null;
}

export const modalsModel = createModel(
  {
    migratedSalary: null,
  } as ModalsModelContext,
  {
    events: {
      close: () => ({}),
      openMigrate: (migratedSalary: Salary) => ({ migratedSalary }),
    },
  },
);

const clearContext = modalsModel.assign(modalsModel.initialContext, "close");

const setMigratedSalary = modalsModel.assign(
  {
    migratedSalary: (_, event) => event.migratedSalary,
  },
  "openMigrate",
);

export const modalsMachine = modalsModel.createMachine({
  id: "modalsMachine",
  context: modalsModel.initialContext,
  initial: "idle",
  states: {
    idle: {
      on: {
        openMigrate: {
          actions: [setMigratedSalary],
          target: "migrate",
        },
      },
    },
    migrate: {
      on: {
        close: {
          actions: [clearContext],
          target: "idle",
        },
      },
    },
  },
});

export const [ModalsContextProvider, useModalsService, useSelectedModalsState, createModalsStateSelector] =
  createXStateContext(modalsMachine);

/// SELECTORS ///

export const getIsMigrateOpen = createModalsStateSelector(state => state.matches("migrate"));

export const getMigratedSalary = createModalsStateSelector(state => state.context.migratedSalary);
