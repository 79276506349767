import { AddressZero } from "@ethersproject/constants";
import { Contract } from "@ethersproject/contracts";
import { useMemo } from "react";

import { SABLIER_ADDRESSES } from "../constants/addresses";
import SABLIER_ABI from "../contracts/Sablier";
import getNetworkName from "../helpers/getNetworkName";
import useContract from "./useContract";
import useWeb3Store from "./useWeb3Store";

export default function useSablierContract(withSignerIfPossible: boolean = true): Contract | null {
  const [store] = useWeb3Store();

  const address = useMemo((): string => {
    if (!store.network.id) {
      return AddressZero;
    }
    const address: string = SABLIER_ADDRESSES[store.network.id];
    if (!address) {
      throw Error(`Sablier not deployed on ${getNetworkName(store.network.id)}`);
    }
    return address;
  }, [store.network.id]);

  return useContract(SABLIER_ABI, address, withSignerIfPossible);
}
