import { rgba } from "polished";
import { ReactElement, ReactNode } from "react";
import styled from "styled-components";

import mediaQuery from "../../theme/mediaQuery";

const Wrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.aliceBlue};
  min-height: 4rem;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  z-index: 100;

  ${props => mediaQuery.lessThan("medium")`
    ${props.theme.snippets.borderAliceBlue}
    ${props.theme.snippets.flexColumnNoWrap}
    align-items: flex-start;
    background-color: ${props.theme.colors.white};
    border-radius: 0.5rem;
    box-shadow: 0rem 0.25rem 1rem -0.5rem ${rgba(props.theme.colors.darkGunmetalBlack, 0.15)};
    padding: 0;
  `}
`;

interface TableRowProps {
  children: ReactNode;
}

function TableRow({ children }: TableRowProps): ReactElement {
  return <Wrapper>{children}</Wrapper>;
}

export default TableRow;
