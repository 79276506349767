import { ReactElement } from "react";
import styled from "styled-components";

import colors from "../../../theme/colors";
import ProgressBar from "../../ProgressBar";

const OuterWrapper = styled.div`
  ${props => props.theme.snippets.flexColumnNoWrap}
  justify-content: center;
  width: 100%;
`;

const InnerWrapper = styled.div`
  ${props => props.theme.snippets.flexColumnNoWrap}
  justify-content: center;
  margin-top: 0.5rem;
  width: 100%;
`;

interface CellProgressProps {
  isCancelled: boolean;
  value: number;
}

function CellProgress({ isCancelled = false, value }: CellProgressProps): ReactElement {
  return (
    <OuterWrapper>
      <InnerWrapper>
        <ProgressBar backgroundColor={colors.aliceBlue} isGrayscale={isCancelled} progress={Math.max(value, 2)} />
      </InnerWrapper>
    </OuterWrapper>
  );
}

export default CellProgress;
