export interface Cancellation {
  id: string;
  senderBalance: string;
}

export interface Salary {
  id: string;
  cancellation?: {
    id: string;
    timestamp: number;
  };
  deposit: string;
  ratePerSecond: string;
  recipient: string;
  sender: string;
  startTime: number;
  stopTime: number;
  timestamp: number;
  token: {
    address: string;
    decimals: number;
    name: string;
    symbol: string;
  };
  withdrawals?: Withdrawal[];
}

export enum SalaryStatus {
  Created = 1,
  Streaming = 2,
  Cancelled = 3,
  Ended = 4,
}

export interface TableColumn {
  flexWeight: number;
  id: TableColumnId;
  label: string;
  reorder: number;
}

export type TableColumnId = "status" | "to" | "value" | "progress" | "startTime" | "stopTime" | "actions";

export interface Withdrawal {
  id: string;
  amount: number;
}
