import { ReactElement, useMemo } from "react";
import { Check, Play, X } from "react-feather";
import styled, { css } from "styled-components";

import mediaQuery from "../../../theme/mediaQuery";
import { SalaryStatus } from "../../../types";

const Wrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  justify-content: center;
  width: 100%;

  ${props => mediaQuery.lessThan("medium")`
    border: 1px solid ${props.theme.colors.aliceBlue}
    border-radius: 0.4rem;
    padding: 0.4rem 0.6rem;
    width: auto;
  `}
`;

const IconWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  border: 1px solid ${props => props.theme.colors.aliceBlue};
  border-radius: 0.6rem;
  height: 1.2rem;
  justify-content: center;
  width: 1.2rem;

  ${mediaQuery.lessThan("medium")`
    margin-left: 0;
  `};
`;

const iconCss = css`
  color: ${props => props.theme.colors.darkGunmetalBlack};
  height: 0.7rem;
  object-fit: contain;
  stroke-width: 2.5;
  width: 0.7rem;
`;

const IconCancelled = styled(X)`
  ${iconCss};
  color: ${props => props.theme.colors.red};
`;

const IconCheck = styled(Check)`
  ${iconCss};
`;

const IconEarning = styled(Play)`
  ${iconCss};
  margin-right: -0.125rem;
`;

const IconDot = styled.div`
  background-color: ${props => props.theme.colors.darkGunmetalBlack};
  border-radius: 0.15rem;
  height: 0.3rem;
  width: 0.3rem;
`;

interface TitleProps {
  status: SalaryStatus;
}

const Title = styled.p<TitleProps>`
  color: ${props => props.theme.colors.darkGunmetalBlack};
  font-family: ${props => props.theme.fonts.catamaran};
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0rem;
  margin-left: 0.5rem;

  ${props =>
    props.status === SalaryStatus.Cancelled &&
    css`
      color: ${props.theme.colors.red};
    `};
`;

interface CellStatusProps {
  status: SalaryStatus;
  withdrawable: true;
}

function CellStatus({ status, withdrawable }: CellStatusProps): ReactElement {
  const title = useMemo((): string => {
    switch (status) {
      case SalaryStatus.Created:
        return "Created";
      case SalaryStatus.Streaming:
        return "Streaming";
      case SalaryStatus.Cancelled:
        return "Cancelled";
      case SalaryStatus.Ended:
        return "Ended";
      default:
        return "Status";
    }
  }, [status]);

  const icon = useMemo((): ReactElement => {
    switch (status) {
      case SalaryStatus.Created:
      case SalaryStatus.Streaming:
        return <IconEarning />;
      case SalaryStatus.Cancelled:
        return <IconCancelled />;
      case SalaryStatus.Ended:
        return withdrawable ? <IconDot /> : <IconCheck />; // TODO: check withdraw
      default:
        return <IconDot />;
    }
  }, [status, withdrawable]);

  return (
    <Wrapper>
      <IconWrapper>{icon}</IconWrapper>
      <Title status={status}>{title}</Title>
    </Wrapper>
  );
}

export default CellStatus;
