import { Contract } from "@ethersproject/contracts";
import { useMachine } from "@xstate/react";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { CheckCircle } from "react-feather";
import styled from "styled-components";

import { MODAL_TRANSITION_DURATION } from "../../constants/time";
import useCancellationSenderBalance from "../../hooks/useCancellationSenderBalance";
import usePayrollContract from "../../hooks/usePayrollContract";
import useSablierContract from "../../hooks/useSablierContract";
import useTokenContract from "../../hooks/useTokenContract";
import useWeb3Store from "../../hooks/useWeb3Store";
import { createMigrationMachine, migrationModel } from "../../machines/migration.machine";
import {
  getIsMigrateOpen,
  getMigratedSalary,
  modalsModel,
  useModalsService,
  useSelectedModalsState,
} from "../../machines/modals.machine";
import { Salary } from "../../types";
import Button from "../button/Button";
import Modal from "./Modal";

const OuterWrapper = styled.div`
  ${props => props.theme.snippets.flexColumnNoWrap}
  padding: 1rem;
  width: 100%;
`;

const TopWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
`;

const TitleLabel = styled.h4`
  ${props => props.theme.snippets.flexRowNoWrap}
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0rem;
  padding: 0rem;
`;

const FlexGrowDiv = styled.div`
  flex-grow: 1;
`;

const InnerWrapper = styled.div`
  ${props => props.theme.snippets.flexColumnNoWrap}
  align-items: flex-start;
`;

const StepWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  margin-top: 1rem;
  width: 100%;
`;

const StepLabel = styled.h5`
  font-family: ${props => props.theme.fonts.fallback};
  font-size: 0.9375rem;
  font-weight: 400;
  margin: 0rem;
  padding: 0rem;
`;

const StyledCheckCircle = styled(CheckCircle)`
  color: ${props => props.theme.colors.crayolaGreen};
  height: 1.25rem;
  margin-right: 0.5rem;
  width: 1.25rem;
`;

const Separator = styled.div`
  align-self: center;
  background-color: ${props => props.theme.colors.aliceBlue};
  height: 1px;
  margin-top: 1rem;
  width: calc(100% - 1rem);
`;

const ErrorLabel = styled.span`
  align-self: center;
  color: ${props => props.theme.colors.red};
  font-size: 0.9375rem;
  margin-top: 1rem;
  text-align: center;
`;

function MigrateModal(): ReactElement {
  const isMigrateOpen: boolean = useSelectedModalsState(getIsMigrateOpen);
  const modalsService = useModalsService();
  const migratedSalary: Salary | null = useSelectedModalsState(getMigratedSalary);
  const [migratedSalaryId, setMigratedSalaryId] = useState<string | undefined>();
  const payrollContract: Contract | null = usePayrollContract();
  const sablierContract: Contract | null = useSablierContract();
  const tokenContract: Contract | null = useTokenContract(migratedSalary?.token.address);
  const [current, send] = useMachine(createMigrationMachine());
  const [store] = useWeb3Store();
  const {
    cancellationSenderBalance,
    error: queryError,
    startPolling,
    stopPolling,
  } = useCancellationSenderBalance(migratedSalary?.id);

  /// CALLBACKS ///

  const onDismissModal = useCallback(() => {
    if (!current.matches("cancelling") && !current.matches("recreating")) {
      modalsService.send(modalsModel.events.close());

      // Delay the reset of the context so that the UI does not flicker.
      setTimeout(() => {
        send(migrationModel.events.reset());
      }, MODAL_TRANSITION_DURATION);
    }
  }, [current, modalsService, send]);

  const onSubmitCancellation = useCallback(() => {
    if (migratedSalary && migratedSalary.id) {
      send(migrationModel.events.cancel({ migratedSalaryId: migratedSalary.id, payrollContract }));
    }
  }, [migratedSalary, payrollContract, send]);

  const onSubmitRecreation = useCallback(() => {
    send(
      migrationModel.events.recreate({
        cancellationSenderBalance,
        migratedSalary,
        sablierContract,
        tokenContract,
        walletAddress: store.wallet.address,
      }),
    );
  }, [cancellationSenderBalance, migratedSalary, sablierContract, send, store.wallet.address, tokenContract]);

  const renderCancelButton = useCallback((): ReactElement => {
    if (current.matches("idle") || current.matches("cancelling") || current.matches("cancelFailure")) {
      return <Button isLoading={current.matches("cancelling")} onClick={onSubmitCancellation} title="Submit" />;
    } else {
      return <StyledCheckCircle />;
    }
  }, [current, onSubmitCancellation]);

  const renderRecreateButton = useCallback((): ReactElement => {
    if (current.matches("success")) {
      return <StyledCheckCircle />;
    } else {
      return (
        <Button
          isDisabled={!current.matches("cancelled") && !current.matches("recreateFailure")}
          isLoading={current.matches("recreating")}
          onClick={onSubmitRecreation}
          title="Submit"
        />
      );
    }
  }, [current, onSubmitRecreation]);

  /// SIDE EFFECTS ///

  useEffect(() => {
    if (migratedSalary?.id) {
      setMigratedSalaryId(migratedSalary.id);
      return undefined;
    } else {
      // Clean the salary id only after the modal animation has finished.
      return () => {
        setMigratedSalaryId(undefined);
      };
    }
  }, [migratedSalary?.id, setMigratedSalaryId]);

  useEffect(() => {
    if (current.matches("cancelling") || current.matches("cancelled")) {
      startPolling(1000);

      return () => {
        stopPolling();
      };
    }
    return undefined;
  }, [current, startPolling, stopPolling]);

  return (
    <Modal isOpen={isMigrateOpen} maxHeight={90} onDismiss={onDismissModal}>
      <OuterWrapper>
        <TopWrapper>
          <TitleLabel>Stream #{migratedSalaryId}</TitleLabel>
          <FlexGrowDiv />
        </TopWrapper>
        <InnerWrapper>
          <StepWrapper>
            <StepLabel>1. Cancel on Sablier v1.0</StepLabel>
            <FlexGrowDiv />
            {renderCancelButton()}
          </StepWrapper>
          <Separator />
          <StepWrapper>
            <StepLabel>2. Re-create on Sablier v1.1</StepLabel>
            <FlexGrowDiv />
            {renderRecreateButton()}
          </StepWrapper>
          {(current.context.error || queryError) && (
            <>
              <Separator />
              <ErrorLabel>{current.context.error || queryError}</ErrorLabel>
            </>
          )}
        </InnerWrapper>
      </OuterWrapper>
    </Modal>
  );
}

export default MigrateModal;
