/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, useCallback, useEffect } from "react";
import styled from "styled-components";

import MigrateModal from "../components/modal/MigrateModal";
import Spinner from "../components/Spinner";
import Table from "../components/table/Table";
import useSenderActiveSalaries from "../hooks/useSenderActiveSalaries";
import { getIsMigrateOpen, useSelectedModalsState } from "../machines/modals.machine";
import mediaQuery from "../theme/mediaQuery";

const CentringFlexColumnNoWrap = styled.div`
  ${props => props.theme.snippets.flexColumnNoWrap}
  align-items: center;
`;

const StyledSpinner = styled(Spinner)`
  margin-left: 0.4rem;
`;

const UnknownErrorLabel = styled.span`
  color: ${props => props.theme.colors.rustyRed};
  font-weight: 600;
  margin: 0.75rem 1.5rem;
  max-width: 30rem;
  text-align: center;
`;

const NoDataLabel = styled.h4`
  font-size: 1rem;
  font-weight: 400;
  margin: 1.5rem 1.25rem;
  max-width: 30rem;
  text-align: center;
`;

const OuterWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: stretch;
  flex-grow: 1;
  justify-content: center;
  overflow-x: hidden;
  padding: 3rem 2rem;

  ${mediaQuery.lessThan("medium")`
    justify-content: flex-start;
    padding: 0.5rem;
  `}
`;

const InnerWrapper = styled.div`
  ${props => props.theme.snippets.borderAliceBlue}
  ${props => props.theme.snippets.flexColumnNoWrap}
  ${props => props.theme.snippets.boxShadowDarkGunmetalBlack}
  align-items: stretch;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.25rem;
  flex-grow: 1;
  justify-content: center;
  max-width: 1200px;

  ${mediaQuery.lessThan("medium")`
    max-width: none;
  `}
`;

const TopWrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  justify-content: flex-start;
  padding: 1.5rem 1.75rem 1rem;
  width: 100%;

  ${mediaQuery.lessThan("medium")`
    padding: 1.5rem 0.6rem 1.5rem 0.8rem;
  `}
`;

const TableWrapper = styled.div`
  ${props => props.theme.snippets.flexColumnNoWrap}
  align-items: center;
  flex-grow: 1;
  justify-content: center;
`;

const TitleLabel = styled.h2`
  color: ${props => props.theme.colors.darkGunmetalBlack};
  font-size: 1.6875rem;
  font-weight: 600;
  margin: 0rem;

  ${mediaQuery.lessThan("medium")`
    font-size: 1.5rem;
  `}
`;

function MainPage(): ReactElement {
  const isMigrateOpen: boolean = useSelectedModalsState(getIsMigrateOpen);
  const { error, loading, refetch, salaries } = useSenderActiveSalaries();

  useEffect(() => {
    async function doRefetch(): Promise<void> {
      if (!refetch) {
        return;
      }

      // Refetch the stream data after migrating one of them.
      if (!isMigrateOpen) {
        await refetch();
      }
    }

    void doRefetch();
  }, [isMigrateOpen, refetch]);

  const render = useCallback((): ReactElement => {
    if (loading) {
      return (
        <CentringFlexColumnNoWrap>
          <StyledSpinner />
        </CentringFlexColumnNoWrap>
      );
    }

    if (error) {
      return (
        <CentringFlexColumnNoWrap>
          <UnknownErrorLabel>
            Oops! An unknown error occurred. Please refresh the page, or visit from another browser or device
          </UnknownErrorLabel>
        </CentringFlexColumnNoWrap>
      );
    }

    if (!salaries || salaries.length === 0) {
      return (
        <CentringFlexColumnNoWrap>
          <NoDataLabel>You have no streams to migrate.</NoDataLabel>
        </CentringFlexColumnNoWrap>
      );
    }

    return (
      <>
        <TopWrapper>
          <TitleLabel>Migrate to v1.1</TitleLabel>
        </TopWrapper>
        <TableWrapper>
          <Table salaries={salaries} />
        </TableWrapper>
      </>
    );
  }, [loading, error, salaries]);

  return (
    <OuterWrapper>
      <InnerWrapper>
        {render()}
        <MigrateModal />
      </InnerWrapper>
    </OuterWrapper>
  );
}

export default MainPage;
