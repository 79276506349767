import { ReactElement, useMemo } from "react";
import styled from "styled-components";

import { shortenAddress } from "../helpers/shortenAddress";

const Label = styled.span`
  color: ${props => props.theme.colors.darkGunmetalBlack};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

interface AccountLabelProps {
  account: string;
}

function AccountLabel({ account }: AccountLabelProps): ReactElement {
  const shortenedAccount = useMemo((): string => {
    if (account) {
      return shortenAddress(account);
    } else {
      return "";
    }
  }, [account]);

  return <Label>{shortenedAccount}</Label>;
}

export default AccountLabel;
