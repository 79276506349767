import { BigNumber, BigNumberish } from "@ethersproject/bignumber";

import getNow from "./getNow";
import getSalaryShare from "./getSalaryShare";

export default function getRecipientSalaryShare(
  value: BigNumberish,
  startTime: BigNumberish,
  stopTime: BigNumberish,
  cancellationTime?: BigNumberish,
): BigNumber {
  const streamDuration = BigNumber.from(stopTime).sub(startTime);
  const elapsedDuration = BigNumber.from(cancellationTime || getNow()).sub(startTime);
  return getSalaryShare(value, streamDuration, elapsedDuration);
}
