import { MAINNET_ID, RINKEBY_ID } from "../constants/networks";

export default function getNetworkName(networkId: number): string {
  switch (networkId) {
    case MAINNET_ID: {
      return "mainnet";
    }
    case RINKEBY_ID: {
      return "rinkeby";
    }
    default:
      return "unknown;";
  }
}
