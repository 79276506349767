import { ReactElement, ReactNode } from "react";
import styled from "styled-components";

import mediaQuery from "../../theme/mediaQuery";

interface WrapperProps {
  className: string;
}

const Wrapper = styled.div<WrapperProps>`
  color: ${props => props.theme.colors.lavenderGray};
  font-family: ${props => props.theme.fonts.catamaran};
  font-size: 0.9375rem;
  font-weight: 600;
  margin: 0;
  text-align: center;
  text-transform: uppercase;

  ${mediaQuery.lessThan("medium")`
    display: flex;
    flex-shrink: 0;
    min-width: 6rem;
    text-align: left;
  `}
`;

interface LabelProps {
  children: ReactNode;
  className?: string;
}

function Label({ children, className = "" }: LabelProps): ReactElement {
  return <Wrapper className={className}>{children}</Wrapper>;
}

export default Label;
