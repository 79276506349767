import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers";
import { API } from "bnc-onboard/dist/src/interfaces";
import { Dispatch, ReactElement, Reducer, createContext, useMemo, useReducer } from "react";

export interface Web3StoreState {
  network: {
    id: number;
    name: string;
  };

  onboard: API | null;

  wallet: {
    address: string;
    provider: Web3Provider | null;
    signer: JsonRpcSigner | null;
  };
}

export type Web3StoreAction =
  | {
      type: "SET_NETWORK_ID";
      payload: number;
    }
  | {
      type: "SET_NETWORK_NAME";
      payload: string;
    }
  | {
      type: "SET_ONBOARD";
      payload: API | null;
    }
  | {
      type: "SET_WALLET_ADDRESS";
      payload: string;
    }
  | {
      type: "SET_WALLET_PROVIDER";
      payload: Web3Provider | null;
    }
  | {
      type: "SET_WALLET_SIGNER";
      payload: JsonRpcSigner | null;
    };

const web3StoreReducer: Reducer<Web3StoreState, Web3StoreAction> = (store, action): Web3StoreState => {
  switch (action.type) {
    case "SET_NETWORK_ID":
      return {
        ...store,
        network: {
          ...store.network,
          id: action.payload,
        },
      };
    case "SET_NETWORK_NAME":
      return {
        ...store,
        network: {
          ...store.network,
          name: action.payload,
        },
      };
    case "SET_ONBOARD":
      return {
        ...store,
        onboard: action.payload,
      };
    case "SET_WALLET_ADDRESS":
      return {
        ...store,
        wallet: {
          ...store.wallet,
          address: action.payload,
        },
      };
    case "SET_WALLET_PROVIDER":
      return {
        ...store,
        wallet: {
          ...store.wallet,
          provider: action.payload,
        },
      };
    case "SET_WALLET_SIGNER":
      return {
        ...store,
        wallet: {
          ...store.wallet,
          signer: action.payload,
        },
      };
    default:
      return store;
  }
};

const initialState: Web3StoreState = {
  network: {
    id: 0,
    name: "",
  },
  onboard: null,
  wallet: {
    address: "",
    provider: null,
    signer: null,
  },
};

export interface Web3StoreContextProps {
  store: Web3StoreState;
  dispatch: Dispatch<Web3StoreAction>;
}

export const Web3StoreContext = createContext<Web3StoreContextProps>({
  store: initialState,
  dispatch: (() => {}) as Dispatch<Web3StoreAction>,
});

interface Web3StoreProps {
  children: ReactElement;
}

export function Web3StoreContextProvider({ children }: Web3StoreProps): ReactElement {
  const [store, dispatch] = useReducer(web3StoreReducer, initialState);

  const memoizedValue = useMemo(() => {
    return { store, dispatch };
  }, [store, dispatch]);

  return <Web3StoreContext.Provider value={memoizedValue}>{children}</Web3StoreContext.Provider>;
}
