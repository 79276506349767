import { Dispatch, SetStateAction, useEffect, useState } from "react";

export default function useShowSpinner(duration: number = 750): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSpinner(true);
    }, duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [duration]);

  return [showSpinner, setShowSpinner];
}
