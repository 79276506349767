import { WalletInitOptions } from "bnc-onboard/dist/src/interfaces";

import packageJson from "../../package.json";
import getEnvVar from "../helpers/getEnvVar";

const rpcUrl: string = `https://mainnet.infura.io/v3/${getEnvVar("REACT_APP_INFURA_API_KEY")}`;

const WALLETS: WalletInitOptions[] = [
  { preferred: true, walletName: "metamask" },
  {
    infuraKey: getEnvVar("REACT_APP_INFURA_API_KEY"),
    preferred: true,
    walletName: "walletConnect",
  },
  {
    rpcUrl,
    walletName: "ledger",
  },
  {
    appUrl: packageJson.homepage,
    email: "hello@sablier.finance",
    rpcUrl,
    walletName: "trezor",
  },
];

export default WALLETS;
