import { useInterpret } from "@xstate/react";
import { ReactElement, ReactNode } from "react";

import { ModalsContextProvider as RawModalsContextProvider, modalsMachine } from "../machines/modals.machine";

interface ModalsProviderProps {
  children: ReactNode;
}

export function ModalsContextProvider({ children }: ModalsProviderProps): ReactElement {
  // We use useInterpret, not useMachine. If we were to use useMachine, the whole app would re-render whenever the
  // modalsService changed state.
  const modalsService = useInterpret(modalsMachine);

  return <RawModalsContextProvider value={modalsService}>{children}</RawModalsContextProvider>;
}
