import styled from "styled-components";

import mediaQuery from "../../../theme/mediaQuery";

const CellAmount = styled.div`
  color: ${props => props.theme.colors.darkGunmetalBlack};
  flex: 1;
  font-family: ${props => props.theme.fonts.catamaran};
  font-size: 0.9375rem;
  font-weight: 500;
  margin: 0rem;
  text-align: center;

  ${props => mediaQuery.lessThan("medium")`
    text-align: left;
    font-family: ${props.theme.fonts.robotoMono};
    font-size: 1.1rem;
  `}
`;

export default CellAmount;
