/* eslint-disable jsx-a11y/no-noninteractive-tabindex, jsx-a11y/tabindex-no-positive */
import "@reach/dialog/styles.css";

import { DialogContent, DialogOverlay } from "@reach/dialog";
import { useGesture } from "@use-gesture/react";
import { rgba, transparentize } from "polished";
import { ReactElement, ReactNode, RefObject } from "react";
import { isMobile } from "react-device-detect";
import { animated, useSpring, useTransition } from "react-spring";
import styled, { css } from "styled-components";

import { MODAL_TRANSITION_DURATION } from "../../constants/time";
import mediaQuery from "../../theme/mediaQuery";

const AnimatedDialogOverlay = animated(DialogOverlay);
const StyledDialogOverlay = styled(AnimatedDialogOverlay)`
  &[data-reach-dialog-overlay] {
    ${props => props.theme.snippets.flexRowNoWrap}
    align-items: center;
    background-color: ${rgba(0, 0, 0, 0.3)};
    justify-content: center;
    overflow: hidden;
    z-index: 100;
  }
`;

const AnimatedDialogContent = animated(DialogContent);

// Destructure to not pass custom props to Dialog DOM element
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDialogContent = styled(({ isMobile, isOpen, maxHeight, minHeight, ...rest }) => (
  <AnimatedDialogContent {...rest} />
)).attrs({
  "aria-label": "dialog",
})`
  overflow-y: auto;

  &[data-reach-dialog-content] {
    ${props => props.theme.snippets.borderAliceBlue}
    ${props => props.theme.snippets.flexRowNoWrap}
    align-self: ${props => (props.isMobile ? "flex-end" : "center")};
    background-color: ${props => props.theme.colors.white};
    border-radius: 1.25rem;
    box-shadow: 0px 4px 8px 0px ${props => transparentize(0.95, props.theme.colors.secondary)};
    margin: 0rem 0rem 2rem 0rem;
    max-width: 420px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;
    width: 50vw;

    ${props =>
      props.maxHeight &&
      css`
        max-height: ${props.maxHeight}vh;
      `}

    ${props =>
      props.minHeight &&
      css`
        min-height: ${props.minHeight}vh;
      `}

    ${mediaQuery.lessThan("medium")`
      width: 65vw;
      margin: 0;
    `}

    ${props => mediaQuery.lessThan("small")`
      width:  85vw;
      ${
        props.isMobile &&
        css`
          width: 100vw;
          border-radius: 20px;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      }
    `}
  }
`;

interface ModalProps {
  children?: ReactNode;
  initialFocusRef?: RefObject<any>;
  isOpen: boolean;
  onDismiss: () => void;
  maxHeight?: number;
  minHeight?: number | false;
}

function Modal({
  children,
  isOpen,
  onDismiss,
  minHeight = false,
  maxHeight = 90,
  initialFocusRef,
}: ModalProps): ReactElement {
  const transitions = useTransition(isOpen, {
    config: { duration: MODAL_TRANSITION_DURATION },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const [{ y }, set] = useSpring(() => ({ y: 0, config: { mass: 1, tension: 210, friction: 20 } }));

  const bind = useGesture({
    onDrag: state => {
      set({
        y: state.down ? state.movement[1] : 0,
      });
      if (state.movement[1] > 300 || (Number(state.velocity) > 3 && state.direction[1] > 0)) {
        onDismiss();
      }
    },
  });

  return transitions(
    (styles, item) =>
      item && (
        <StyledDialogOverlay
          dangerouslyBypassFocusLock
          initialFocusRef={initialFocusRef}
          onDismiss={onDismiss}
          style={styles}
          unstable_lockFocusAcrossFrames={false}
        >
          <StyledDialogContent
            {...(isMobile
              ? {
                  ...bind(),
                  style: { transform: y.to(y => `translateY(${(y as number) > 0 ? y : 0}px)`) },
                }
              : {})}
            aria-label="dialog content"
            maxHeight={maxHeight}
            minHeight={minHeight}
            isMobile={isMobile}
          >
            {/* prevents the automatic focusing of inputs on isMobile by the reach dialog */}
            {!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
            {children}
          </StyledDialogContent>
        </StyledDialogOverlay>
      ),
  );
}

export default Modal;
