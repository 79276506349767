import { rgba } from "polished";
import React, { MouseEventHandler, ReactElement, useCallback } from "react";
import styled, { css } from "styled-components";

import colors from "../../theme/colors";
import Spinner from "../Spinner";

export interface WrapperProps {
  isDisabled: boolean;
  isLoading: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  ${props => props.theme.snippets.borderAliceBlue}
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 3.125rem;
  color: ${props => props.theme.colors.darkGunmetalBlack};
  cursor: pointer;
  justify-content: center;
  min-height: 1.25rem;
  padding: 0.4375rem 1.5rem;
  position: relative;
  transition: background-color 200ms;

  &:hover {
    background-color: ${props => props.theme.colors.ghostWhite};
    transition: background-color 200ms;
  }

  ${props =>
    (props.isDisabled || props.isLoading) &&
    css`
      cursor: not-allowed;
      user-select: none;

      &:active {
        pointer-events: none;
      }
    `}
`;

const SpinnerWrapper = styled.div`
  left: 50%;
  position: absolute;
  transform: translateX(-50%); ;
`;

interface TitleLabelProps {
  isLoading: boolean;
}

const TitleLabel = styled.div<TitleLabelProps>`
  font-size: 0.875rem;
  font-weight: 600;
  margin: 0rem 0.1875rem;
  margin-top: 0.125rem;

  ${props =>
    props.isLoading &&
    css`
      visibility: hidden;
    `}
`;

interface ButtonProps {
  childrenLeft?: ReactElement[];
  childrenRight?: ReactElement[];
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  title: string;
}

function Button({
  childrenLeft = [],
  childrenRight = [],
  className = "",
  isDisabled = false,
  isLoading = false,
  onClick = (() => {}) as MouseEventHandler<HTMLElement>,
  title = "",
}: ButtonProps): ReactElement {
  const onClickMiddleware = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (isDisabled) {
        event.preventDefault();
      } else {
        onClick(event);
      }
    },
    [isDisabled, onClick],
  );

  return (
    <Wrapper className={className} isDisabled={isDisabled} isLoading={isLoading} onClick={onClickMiddleware}>
      {childrenLeft}
      <TitleLabel isLoading={isLoading}>{title}</TitleLabel>
      {childrenRight}
      {isLoading && (
        <SpinnerWrapper data-component="loader">
          <Spinner
            borderColor={rgba(colors.darkGunmetalBlack, 0.4)}
            color={colors.darkGunmetalBlack}
            delay={0}
            size={1.2}
          />
        </SpinnerWrapper>
      )}
    </Wrapper>
  );
}

export default Button;
