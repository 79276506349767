import { ReactElement } from "react";
import styled from "styled-components";

import mediaQuery from "../../theme/mediaQuery";
import { TableColumn } from "../../types";
import TableLabel from "./TableLabel";

const Wrapper = styled.div`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.aliceBlue};
  width: 100%;

  ${mediaQuery.lessThan("medium")`
    display: none;
  `}
`;

interface CellProps {
  flexWeight: number;
}

const Cell = styled.div<CellProps>`
  ${props => props.theme.snippets.flexRowNoWrap}
  align-items: center;
  flex-basis: 0%;
  flex-grow: ${props => props.flexWeight};
  flex-shrink: ${props => props.flexWeight};
  justify-content: center;
  min-height: 4rem;
`;

interface TableHeaderProps {
  columns: TableColumn[];
}

function TableHeader({ columns }: TableHeaderProps): ReactElement {
  return (
    <Wrapper>
      {columns.map(({ flexWeight, id, label }) => (
        <Cell flexWeight={flexWeight} key={id}>
          <TableLabel>{label}</TableLabel>
        </Cell>
      ))}
    </Wrapper>
  );
}

export default TableHeader;
