import styled from "styled-components";

import mediaQuery from "../../../theme/mediaQuery";

const CellText = styled.div`
  color: ${props => props.theme.colors.darkGunmetalBlack};
  flex: 1;
  font-family: ${props => props.theme.fonts.catamaran};
  font-size: 0.9375rem;
  font-weight: 500;
  margin: 0rem;
  text-align: center;

  ${mediaQuery.lessThan("medium")`
    font-weight: 500;
    text-align: left;
  `}
`;

export default CellText;
