import { Salary, SalaryStatus } from "../types";

export default function getSalaryStatus(salary: Salary): SalaryStatus {
  const { cancellation, startTime, stopTime } = salary;
  if (cancellation) {
    return SalaryStatus.Cancelled;
  }
  const now = new Date().getTime();
  if (now < new Date(startTime * 1000).getTime()) {
    return SalaryStatus.Created;
  }
  if (now >= new Date(stopTime * 1000).getTime()) {
    return SalaryStatus.Ended;
  }
  return SalaryStatus.Streaming;
}
